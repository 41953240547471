<template>
    <div class="collection-detail-page wid1200">
        <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        <div class="collection-detail-wrap">
            <div
                :class="['left-collection-detail-wrap', ($route.name != 'pDB' && $route.name != 'eDB' && $route.name != 'iDB') ? 'left-collection-detail' : 'w100']">
                <div class="left-collection">
                    <div class="top-info-left">
                        <div class="top-info-left-wrap">
                            <img :src="detail.titleImg" v-if="detail && detail.titleImg" alt="" />
                            <img v-else alt="" />
                        </div>
                        <div class="top-info">
                            <h5><span>{{detail.title}}</span></h5>
                            <p v-if="detail.author">主编：<span>{{detail.author}}</span></p>
                            <p
                                v-if="detail.subject && $route.name != 'pDB' && $route.name != 'eDB' && $route.name != 'iDB'">
                                所属学科：<span>{{detail.subject}}</span></p>
                            <p v-if="detail.publisher">出版单位：<span>{{detail.publisher}}</span></p>
                            <p v-if="detail.releaseDate">出版日期：<span>{{detail.releaseDate}}</span></p>
                            <p v-if="detail.isbn">ISBN：<span>{{detail.isbn}}</span></p>
                            <button @click="generateCitation">生成引文</button>

                        </div>
                    </div>
                    <!-- <div class="author-info com-div" v-if="$route.name != 'pDB' && $route.name != 'eDB' && $route.name != 'iDB'">
                        <h5><span>作者信息</span></h5>
                        <div>
                            <div v-for="(item, index) in detail.authorList" :key="index">
                                <h6 v-if="item.name">{{item.name}}</h6>

                                <p v-if="item.detail" v-html="item.detail"></p>
                            </div>
                        </div>
                    </div> -->
                    <div class="content-info com-div"
                        v-if="$route.name != 'pDB' && $route.name != 'eDB' && $route.name != 'iDB'">
                        <h5><span>内容简介</span></h5>
                        <p v-if="detail.abstractCn" v-html="detail.abstractCn"></p>
                    </div>
                    <div class="catalog com-div"
                        v-if="$route.name != 'pDB' && $route.name != 'eDB' && $route.name != 'iDB'">
                        <h5><span>目录</span></h5>
                        <div class="catalog-content">
                            <ul class="left-catalog" v-if="isCatelog">
                                <li v-for="iterm in detail.bookList" :key="iterm.id"
                                    :class="{'active':curNum==iterm.collectnum}"
                                    @click="changetab(iterm.collectnum, iterm.id)">
                                    <a>
                                        <p v-if="iterm.collectnum">第 {{iterm.collectnum}} 期</p>
                                        <!-- collectnum   collectnum-->
                                        <!--<span>({{iterm.releaseDate}})</span>-->
                                    </a>
                                </li>
                            </ul>
                            <!-- <ul class="right-catalog">
                                <li v-for="item in testBook" :key="item.id">
                                    <h5>{{item.title}}</h5>
                                    <div v-for="(i,ind) in item.children" :key="ind">
                                        <p>{{i.title}}</p>
                                        <a>李扬 曹红辉</a>
                                    </div>
                                </li>
                            </ul> -->
                            <ul class="right-catalog">
                                <li v-for="iterm in catalogList" :key="iterm.id">
                                    <div v-if="iterm.catalogType == 0">
                                        <!-- 0:一级标题 1:普通文章 -->

                                        <h5 :class="[isCatelog? 'small-p' : 'big-p']"
                                            @click="golink(iterm.id, iterm.searchTitle)" v-if="iterm.searchTitle" v-html="iterm.searchTitle">
                                            </h5>
                                        <h5 :class="[isCatelog? 'small-p' : 'big-p']"
                                            @click="golink(iterm.id, iterm.title)"
                                            v-if="(!iterm.searchTitle) && iterm.title" v-html="iterm.title"></h5>
                                    </div>
                                    <div class="bot-wrap" v-if="iterm.catalogType == 1">
                                        <div :class="[isCatelog? 'small-p' : 'big-p']"
                                            @click="golink(iterm.id, iterm.searchTitle)" v-if="iterm.searchTitle">
                                            <p  v-if="iterm.openAccessStatus == 1" style="padding-top: 3px; box-sizing: border-box; padding-right: 4px;"><img src="../assets/img/lock.png" alt=""  style="width: 20px"/></p>

                                            <p  v-html="iterm.searchTitle"></p>
                                            </div>

                                        <div :class="[isCatelog? 'small-p' : 'big-p']"
                                            @click="golink(iterm.id, iterm.title)"
                                            v-if="(!iterm.searchTitle) && iterm.title">
                                            <p v-if="iterm.openAccessStatus == 1" style="padding-top: 3px; box-sizing: border-box; padding-right: 4px;"><img src="../assets/img/lock.png" alt=""  style="width: 20px"/></p>
                                            <p v-html="iterm.title"></p>
                                            </div>
                                        <a :title="iterm.author"
                                            v-if="iterm.author">{{iterm.author.length<=14 ? iterm.author : (iterm.author.slice( 0, 14)+'...')}}</a>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="catalog com-div" v-else>
                        <h5><span>目录</span></h5>
                        <div class="catalog-content">
                            <div v-if="$route.name == 'pDB'" v-html="detail.content" style="font-size: 14px;"></div>
                            <div v-else v-html="detail.catalog" style="font-size: 14px;"></div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="right-collection-detail"
                v-if="$route.name != 'pDB' && $route.name != 'eDB' && $route.name != 'iDB'">

                <div class="relevant-article-wrap">
                    <div class="com-title">
                        <div>
                            <img class="title-img" src="../assets/img/icon3.png" alt="" />
                            <h5>相关文章</h5>
                        </div>
                    </div>

                    <div class="relevant-article">
                        <ul>
                            <li v-for="item in articleList" :key="item.id" @click="goRelevant('article', item)">
                                {{item.searchTitle ? item.searchTitle : item.title}}</li>
                        </ul>
                    </div>

                </div>

                <div class="relevant-anthology-wrap">
                    <div class="com-title">
                        <div>
                            <img class="title-img" src="../assets/img/icon1.png" alt="" />
                            <h5>相关集刊</h5>
                        </div>
                    </div>
                    <div class="relevant-anthology">
                        <img v-if="bookList[0] && bookList[0].titleImg" :src="bookList[0].titleImg" alt=""
                            @click="goRelevant('wenji', bookList[0])" />
                        <img v-else alt="" />
                        <div class="relevant-anthology-main">
                            <h5 @click="goRelevant('wenji', bookList[0])" v-if="bookList[0] && bookList[0].title">
                                {{bookList[0].title}}</h5>
                            <p v-if="bookList[0] && bookList[0].author">主编：{{bookList[0].author}}</p>
                            <p v-if="bookList[0] && bookList[0].abstractCn">集刊简介：{{bookList[0].abstractCn}}</p>
                        </div>
                    </div>
                    <ul>
                        <li v-for="(item, index) in bookList" :key="index" v-if="index>0 && item.title"
                            @click="goRelevant('book', item)">{{item.title}}</li>
                    </ul>
                </div>

                <div class="relevant-anthology-wrap">
                    <div class="com-title">
                        <div>
                            <img class="title-img" src="../assets/img/icon2.png" alt="" />
                            <h5>相关文集</h5>
                        </div>
                    </div>
                    <div class="relevant-anthology">
                        <img v-if="wenjiList[0] && wenjiList[0].titleImg" :src="wenjiList[0].titleImg" alt=""
                            @click="goRelevant('wenji', wenjiList[0])" />
                        <img v-else alt="" />
                        <div class="relevant-anthology-main">
                            <h5 @click="goRelevant('wenji', wenjiList[0])" v-if="wenjiList[0] && wenjiList[0].title">
                                {{wenjiList[0].title}}</h5>
                            <p v-if="wenjiList[0] && wenjiList[0].author">主编：{{wenjiList[0].author}}</p>
                            <p v-if="wenjiList[0] && wenjiList[0].abstractCn">集刊简介：{{wenjiList[0].abstractCn}}</p>
                        </div>
                    </div>
                    <ul>
                        <li v-for="(item, index) in wenjiList" :key="index" v-if="index>0 && item.title"
                            @click="goRelevant('wenji', item)">{{item.title}}</li>
                    </ul>

                </div>
            </div>

        </div>

        <el-dialog title="引文" :visible.sync="isQuote" width="30%">
            <div>
                <textarea id="copy" v-model="quoteContent"  style="width: 100%;   height: auto; min-height: 120px; border: none;font-size: 15px; line-height: 25px;color: #333;outline: none;" readonly="readonly" ></textarea>
                 <div class="btn-wrap" style="text-align: center; margin-top: 30px;">
                     <button @click="copyQuote" style="    width: 80px; height: 30px; background: #ff8600; font-size: 14px; font-weight: 500; color: #fff; margin-right: 20px; border: none; border-radius: 4px;">复制</button>
                 </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
    components: { BreadCrumb },
    data() {
        return {
            pN: '',
            pageName: '文集详情页',
            isQuote: false,
            quoteContent: '',
            activeIndex: 1,
            curNum: 0,
            detail: '',
            catalogList: [],
            downloadId: 1,
            articleList: [],
            bookList: [],
            wenjiList: [],
            isCatelog: false,
            testBook: [
                {
                    title: '1大标题',
                    id: '1213123213',
                    children: [
                        {
                            title: 'a小标题'
                        },
                        {
                            title: 'a小标题'
                        },
                        {
                            title: 'a小标题'
                        },
                        {
                            title: 'a小标题'
                        },
                        {
                            title: 'a小标题'
                        }
                    ]
                },
                {
                    title: '2da',
                    id: '789789789'
                },
                {
                    title: '3大标题',
                    id: 'hfiuewghiuhi',
                    children: [
                        {
                            title: 'b小标题'
                        },
                        {
                            title: 'b小标题'
                        },
                        {
                            title: 'b小标题'
                        },
                        {
                            title: 'b小标题'
                        },
                        {
                            title: 'b小标题'
                        }
                    ]
                }
            ]
        }
    },
    watch: {
        $route: 'init'
    },
    methods: {
        init() {
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }

            if (this.$route.name == 'pDB') {
                //库外预印本书详情
                this.getPreprintBook(this.$route.query.id)
            } else if (this.$route.name == 'eDB') {
                //库外学者书详情
                this.getExpertBook(this.$route.query.id)
            } else if (this.$route.name == 'iDB') {
                //库外机构书详情
                this.getInsBook(this.$route.query.id)
            } else {
                this.getDetail(this.$route.query.id)
                this.getQKList(this.$route.query.id)
                this.getRelevant(this.$route.query.id)
            }
        },
        copyQuote () {

            //获取input对象
            var input = document.getElementById('copy')
            //选中input标签
            input.select()
            //执行复制
            document.execCommand('copy')
            this.$message({
              message: '复制成功',
              type: 'success'
            })
        },
        generateCitation() {
            //生成引文
            this.quoteContent = this.detail.citation
            this.isQuote = true
            // console.log('this.quoteContent', this.quoteContent, 'this.isquote', this.isquote)
        },
        switchNav(num) {
            this.activeIndex = num
        },
        goRelevant(type, item) {
            // if (item.resourceType == 'COLLECTED_PAPERS') {
            //     this.$router.push({path: '/c/c', query: {id: item.id, pN: encodeURI(this.pageName)}})
            // } else if (item.resourceType == 'WENJI'  ||  item.resourceType == 'BOOK') {
            //     this.$router.push({path: '/collectionDetail/c', query: {id: item.id, pN: encodeURI(this.pageName)}})
            // } else if (item.resourceType == 'ARTICLE') {
            //     this.$router.push({path: '/aD/a', query: {id: item.id, pN: encodeURI(this.pageName)}})
            // }

            if (type == 'wenji' || type == 'book') {
                this.$router.push({ path: '/collectionDetail/c', query: { id: item.id, pN: encodeURI(this.pageName) } })
            } else if (type == 'article') {
                this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName) } })
                // title: item.title
            }
        },
        golink(id, title) {
            // this.$router.push({name:'iedolDetail',params:{id:id}})
            this.$router.push({ path: '/aD/a', query: { id: id, pN: encodeURI(this.pageName) } })
            // title: title
        },
        getRelevant(id) {
            var _this = this
            this.$http({
                methods: 'get',
                url: '/admin/api/book/recommendList?bookId=' + id
            }).then(res => {
                // console.log(res)
                if (res.data.code == 0) {
                    _this.articleList = res.data.data.articleList
                    _this.bookList = res.data.data.bookList
                    _this.wenjiList = res.data.data.wenjiList
                }
            })
        },
        changetab(num, id) {
            this.curNum = num
            this.downloadId = id
            this.getDetail(id) //1741677
            this.getQKList(id) //101659
        },
        getInsBook(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/research/resourceDetail?resourceId=' + id
            }).then(res => {
                _this.detail = res.data.data
                _this.pageName = _this.detail.title
                // console.log('ins', _this.detail)
                // if (_this.detail.attachment) {
                //         _this.url = _this.detail.attachment.split('/admin')[1]
                //         console.log('_this.url', _this.url)
                //     }

                _this.isCatelog = false
                _this.pageName = _this.detail.title
                _this.curNum = 0 //res.data.data.collectnum
            })
        },
        getPreprintBook(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/aloneArticle/partnerDetail?id=' + id
            }).then(res => {
                _this.detail = res.data.data
                _this.pageName = _this.detail.title

                // console.log('preBook', _this.detail)
                // if (_this.detail.attachment) {
                //         _this.url = _this.detail.attachment.split('/admin')[1]
                //         console.log('_this.url', _this.url)
                //     }

                _this.isCatelog = false
                _this.pageName = _this.detail.title
                _this.curNum = 0 //res.data.data.collectnum
            })
        },
        getExpertBook(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/scholar/resourceDetail?resourceId=' + id
            }).then(res => {
                _this.detail = res.data.data
                _this.pageName = _this.detail.title

                // console.log('expert', _this.detail)
                // if (_this.detail.attachment) {
                //         _this.url = _this.detail.attachment.split('/admin')[1]
                //         console.log('_this.url', _this.url)
                //     }

                _this.isCatelog = false
                _this.pageName = _this.detail.title
                _this.curNum = 0 //res.data.data.collectnum
            })
        },
        getDetail(id) {
            var _this = this
            _this.$http
                .get('/admin/api/book/detail', {
                    params: {
                        id: id
                    }
                })
                .then(res => {
                    _this.detail = res.data.data
                    if (_this.detail.resourceType == 'BOOK') {
                        _this.isCatelog = true
                    } else {
                        _this.isCatelog = false
                    }
                    _this.pageName = _this.detail.title
                    _this.curNum = res.data.data.collectnum
                    // if(this.detail.releaseDate){
                    //     this.detail.releaseDate = this.changeDate( this.detail.releaseDate)
                    // }
                    // this.getHotList(this.detail.collectedPapersId)
                    // this.getJkDetail(this.detail.collectedPapersId);
                    // this.getNewList(this.detail.collectedPapersId);
                })
        },
        getQKList(id) {
            var _this = this
            _this.$http
                .get('/admin/api/book/catalog', {
                    params: {
                        id: id,
                        type: 'left'
                    }
                })
                .then(res => {
                    _this.catalogList = res.data.data

                })
        }
    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
.collection-detail-page {
    .collection-detail-wrap {
        display: flex;
        justify-content: space-between;
    }
    .com-title {
        height: 45px;
        line-height: 45px;
        border-bottom: 2px solid #dedede;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 11px;
                margin-left: 3px;
            }
            h5 {
                height: 45px;
                line-height: 45px;
                font-size: 24px;
                color: #000000;
                border-bottom: 3px solid #bd1a2d;
            }
        }

        ul {
            display: flex;
            li {
                font-size: 18px;
                margin-right: 18px;
                cursor: pointer;
                line-height: 36px;
                color: #333;
            }
        }
        span {
            font-size: 14px;
            color: #404040;
            cursor: pointer;
            img {
                width: 4px;
                height: 8px;
                margin-left: 10px;
            }
        }
    }
    .left-collection-detail {
        width: 830px;
    }
    .w100 {
        width: 100%;
    }
    .left-collection-detail-wrap {
        .left-collection {
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-bottom: 24px;
            box-sizing: border-box;
            padding: 28px;
            min-height: 700px;
            .top-info-left {
                display: flex;
                .top-info-left-wrap {
                    width: 204px;
                    height: 272px;
                    // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
                    margin-right: 30px;
                    img {
                        width: 204px;
                        height: 272px;
                    }
                }

                .top-info {
                    flex: 1;
                    h5 {
                        margin-bottom: 15px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 540px;
                        span {
                            font-size: 24px;
                            height: 39px;
                            border-bottom: 3px solid #bd1a2d;
                        }
                    }
                    p {
                        line-height: 37px;
                        font-size: 17px;
                        color: #666;

                        span {
                            display: inline-block;
                            font-size: 17px;
                            color: #333;
                        }
                    }
                    button {
                        width: 90px;
                        height: 30px;
                        background: #ff8600;
                        font-size: 14px;
                        color: #fff;
                        margin-right: 20px;
                        border: none;
                        border-radius: 4px;
                        margin-top: 9px;
                    }
                }
            }
            .author-info {
                div {
                    div {
                        h6 {
                            line-height: 40px;
                            font-size: 20px;
                        }
                        p {
                            line-height: 26px;
                            font-size: 16px;
                        }
                    }
                }
            }
            .com-div {
                h5 {
                    margin: 20px 0 14px;
                    span {
                        color: #333;
                        font-size: 24px;
                        height: 37px;
                        box-sizing: border-box;
                        border-bottom: 2px solid #bd1a2d;
                    }
                }
                p {
                    line-height: 26px;
                    font-size: 16px;
                }
            }
            .catalog {
                .catalog-content {
                    display: flex;
                    .left-catalog {
                        margin-right: 18px;
                        li {
                            width: 120px;
                            height: 36px;
                            box-sizing: border-box;
                            line-height: 36px;
                            color: #666;
                            font-size: 16px;
                            border: 1px solid #e6e6e6;
                            text-align: center;
                            cursor: pointer;
                            a {
                                display: block;
                                line-height: 36px;
                                color: #666;
                                p {
                                    line-height: 36px;
                                }
                            }
                        }
                        li:hover {
                            a {
                                background: #bd1a2d;
                                color: #fff;
                                p {
                                    background: #bd1a2d;
                                    color: #fff;
                                }
                            }
                        }
                        li.active {
                            a {
                                background: #bd1a2d;
                                color: #fff;
                                p {
                                    background: #bd1a2d;
                                    color: #fff;
                                }
                            }
                        }
                    }
                    ul.right-catalog {
                        flex: 1;
                        li:first-child {
                            h5 {
                                margin-top: 0px;
                            }
                        }
                        li {
                            h5 {
                                font-size: 16px;
                                color: #333;
                                line-height: 38px;
                                font-weight: bold;
                                margin: 20px 0 8px;
                            }
                            div.bot-wrap {
                                display: flex;

                                align-items: center;
                                position: relative;
                                line-height: 38px;
                                justify-content: space-between;
                                background: url(../assets/img/border.png);
                                background-position: 6% 49%;
                                background-repeat: repeat-x;
                                background-size: 1% 1px;
                                margin-bottom: 12px;
                                div {
                                    display: flex;
                                    padding-left: 6px;
                                    font-size: 16px;
                                    color: #333;
                                    background: #fff;
                                    padding-right: 7px;
                                    cursor: pointer;
                                    p {
                                        font-size: 16px;
                                        color: #333;
                                    }
                                }
                                .small-p {
                                    max-width: 397px;
                                }
                                .big-p {
                                    max-width: 525px;
                                }
                                a {
                                    font-size: 16px;
                                    color: #666;
                                    background: #fff;
                                    padding-left: 5px;
                                    display: block;
                                    line-height: 26px;
                                    cursor: auto;
                                }
                                .small-p >>> p .role3 {
                                    /* 4补字 3行内 */
                                    display: inline-block;
                                    max-height: 18px;
                                }
                                .small-p >>> p .role4 {
                                    vertical-align: middle;
                                    width: 16px !important;
                                    height: 16px !important;
                                    margin-top: -2px;
                                }
                            }
                            div.bot-wrap::before {
                                content: '';
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #ffac13;
                                position: absolute;
                                left: -4px;
                                top: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .right-collection-detail {
        width: 316px;

        .relevant-anthology-wrap {
            padding: 0 18px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-bottom: 24px;
            .com-title {
                div {
                    img {
                        height: 29px;
                    }
                }
            }
            .relevant-anthology {
                display: flex;
                justify-content: space-between;
                padding-top: 14px;
                img {
                    width: 106px;
                    height: 142px;
                    margin-bottom: 10px;
                }
                .relevant-anthology-main {
                    width: 162px;
                    h5,
                    p:nth-child(2) {
                        width: 162px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    h5 {
                        font-size: 16px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                    p {
                        font-size: 14px;
                        color: #666;
                        line-height: 22px;
                    }
                    p:nth-child(3) {
                        width: 162px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            ul {
                margin-bottom: 13px;
                li {
                    width: 278px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 36px;
                    height: 36px;
                    font-size: 16px;
                    color: #333333;
                    cursor: pointer;
                    position: relative;
                }
                li::before {
                    position: absolute;
                    left: 0;
                    top: 18px;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                }
            }
        }
        .relevant-article-wrap {
            padding: 0 18px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            margin-bottom: 24px;
            .com-title {
                div {
                    img {
                        height: 29px;
                    }
                }
            }
            .relevant-article {
                ul {
                    padding: 10px 0 14px;
                    li {
                        line-height: 37px;
                        height: 37px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-left: 10px;
                        position: relative;
                        font-size: 16px;
                        color: #333;
                        width: 278px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;
                        box-sizing: border-box;
                    }
                    li::before {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #ffac13;
                        position: absolute;
                        left: 0;
                        top: 18px;
                    }
                }
            }
        }
    }
}
</style>
